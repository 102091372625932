import {useCallback, useContext, useEffect, useState} from "react";
import {CycleContext} from "../contexts/CycleContext";
import {SeriesContext} from "../contexts/SeriesContext";
import {
    DefaultButton,
    DefaultButtonText,
    HomeContainer,
    PushupsNumber,
    HeaderText,
    SeriesNumberBullet,
    SeriesNumberBulletsContainer,
    SeriesNumberBulletsContainerBackgroundLine,
    SeriesNumberBulletText,
    SeriesNumberContainer, GreenHeaderNoteContainer, GreenHeaderNoteText
} from "../styles/styles";
import {polishNumbersFemine} from "../data/polishNumbersFemine";
import * as Speech from "expo-speech";
import {polishPushupDeclention} from "../data/polishPushupDeclention";
import {SeriesDataContext} from "../contexts/SeriesDataContex";
import {useFocusEffect} from "@react-navigation/native";

export default function SeriesScreen({navigation}) {
    const {cycleNumber} = useContext(CycleContext);
    const {seriesNumber} = useContext(SeriesContext);
    const {seriesData} = useContext(SeriesDataContext);
    const [numberOfSeriesToday, setNumberOfSeriesToday] = useState(5)

    const checkPushupToDoInThisCurrentSeries = () => {
        const day = seriesData.find(o => o.day === cycleNumber)
        const seriesObject = day["series"];
        return seriesObject[seriesNumber];
    }

    useEffect(() => {
        const day = seriesData.find(o => o.day === cycleNumber)
        const seriesToday = day["number_of_series"];
        setNumberOfSeriesToday(seriesToday)
    }, [])

    const handleGoToRelaxPage = () => {
        if (seriesNumber < numberOfSeriesToday) {
            navigation.navigate("Relax")
        } else navigation.replace("ThankYou")
    }

    useFocusEffect(
        useCallback(() => {
            const pushupsNumbersToDoInPolish = polishNumbersFemine[checkPushupToDoInThisCurrentSeries()];
            const pushupsDeclensionToDoInPolish = polishPushupDeclention[checkPushupToDoInThisCurrentSeries()];
            const thingToSay = seriesNumber === numberOfSeriesToday ? `teraz zrób max, minimum ${pushupsNumbersToDoInPolish} ${pushupsDeclensionToDoInPolish}` : `zrób ${pushupsNumbersToDoInPolish} ${pushupsDeclensionToDoInPolish}`;
            Speech.speak(thingToSay, {language: "pl"});
        }, [seriesNumber])
    );

    const showSeriesBullets = () => {
        const day = seriesData.find(o => o.day === cycleNumber)
        const seriesObject = day["series"];
        const seriesArray = Object.keys(seriesObject).map((key) => [Number(key), seriesObject[key]]);
        return (
            <>
                {seriesArray.map((item, index) => (
                    <SeriesNumberBullet key={index}
                                        style={seriesNumber !== index + 1 && {backgroundColor: "#D7D7D7FF"}}>
                        <SeriesNumberBulletText>{index + 1}</SeriesNumberBulletText>
                    </SeriesNumberBullet>
                ))}
            </>
        )
    }

    return (
        <HomeContainer
            source={require('../../assets/light_pushup_drawings_background.png')}
        >
            <GreenHeaderNoteContainer>
                <GreenHeaderNoteText>
                    {cycleNumber}. dzień treningu
                </GreenHeaderNoteText>
            </GreenHeaderNoteContainer>
            <SeriesNumberContainer>
                <HeaderText>
                    SERIA
                </HeaderText>
                <SeriesNumberBulletsContainer>
                    {showSeriesBullets()}
                    <SeriesNumberBulletsContainerBackgroundLine/>
                </SeriesNumberBulletsContainer>
            </SeriesNumberContainer>
            {seriesNumber < numberOfSeriesToday ? (
                <PushupsNumber>
                    {checkPushupToDoInThisCurrentSeries()}
                </PushupsNumber>
            ) : (
                <>
                    <PushupsNumber>
                        MAX
                    </PushupsNumber>
                    <HeaderText>
                        (minimum {checkPushupToDoInThisCurrentSeries()})
                    </HeaderText>
                </>
            )}
            <DefaultButton>
                <DefaultButtonText onPress={() => handleGoToRelaxPage()}>ZROBIONE</DefaultButtonText>
            </DefaultButton>
        </HomeContainer>
    )
}