export const seriesDataBelow6 = [
    {
        "day": 1,
        "series": {
            1: 2,
            2: 3,
            3: 2,
            4: 2,
            5: 3
        }
    },
    {
        "day": 2,
        "series": {
            1: 3,
            2: 4,
            3: 2,
            4: 3,
            5: 4
        }
    },
    {
        "day": 3,
        "series": {
            1: 4,
            2: 5,
            3: 4,
            4: 4,
            5: 5
        }
    },
    {
        "day": 4,
        "series": {
            1: 5,
            2: 6,
            3: 4,
            4: 4,
            5: 6
        }
    },
    {
        "day": 5,
        "series": {
            1: 5,
            2: 6,
            3: 4,
            4: 4,
            5: 7
        }
    },
    {
        "day": 6,
        "series": {
            1: 5,
            2: 7,
            3: 5,
            4: 5,
            5: 7
        }
    },

]