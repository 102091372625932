import {StatusBar} from 'expo-status-bar';
import HomeScreen from "./app/screens/HomeScreen";
import {CycleContext} from "./app/contexts/CycleContext";
import {useContext, useState} from "react";
import {SeriesContext} from "./app/contexts/SeriesContext";
import {PushupTestContext} from "./app/contexts/PushupTestContext";
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import SeriesScreen from "./app/screens/SeriesScreen";
import RelaxScreen from "./app/screens/RelaxScreen";
import ThankYouScreen from "./app/screens/ThankYouScreen";
import {SeriesDataContext} from "./app/contexts/SeriesDataContex";
import PushupTestScreen from "./app/screens/PushupTestScreen";
import CycleDayScreen from "./app/screens/CycleDayScreen";
import WarmupScreen from "./app/screens/WarmupScreen";

import {useKeepAwake} from "expo-keep-awake";
import {WorkoutTimeStartContext} from "./app/contexts/WorkoutTimeStartContext";
import {CycleDateStartContext} from "./app/contexts/CycleDateStartContext";

export default function App() {
    const [cycle, setCycle] = useState("");
    const [series, setSeries] = useState("");
    const [test, setTest] = useState("");
    const [data, setData] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [startDate, setStartDate] = useState("");
    const {cycleNumber, setCycleNumber} = useContext(CycleContext);
    const {seriesNumber, setSeriesNumber} = useContext(SeriesContext);
    const {pushupTest, setPushupTest} = useContext(PushupTestContext);
    const {seriesData, setSeriesData} = useContext(SeriesDataContext);
    const {workoutTimeStart, setWorkoutTimeStart} = useContext(WorkoutTimeStartContext);
    const {cycleDateStart, setCycleDateStart} = useContext(CycleDateStartContext)

    const Stack = createNativeStackNavigator();

    useKeepAwake();

    return (
        <PushupTestContext.Provider value={{pushupTest: test, setPushupTest: setTest}}>
            <CycleContext.Provider value={{cycleNumber: cycle, setCycleNumber: setCycle}}>
                <SeriesContext.Provider value={{seriesNumber: series, setSeriesNumber: setSeries}}>
                    <SeriesDataContext.Provider value={{seriesData: data, setSeriesData: setData}}>
                        <WorkoutTimeStartContext.Provider
                            value={{workoutTimeStart: startTime, setWorkoutTimeStart: setStartTime}}>
                            <CycleDateStartContext.Provider
                                value={{cycleDateStart: startDate, setCycleDateStart: setStartDate}}>
                                <NavigationContainer>
                                    <Stack.Navigator
                                    >
                                        <Stack.Screen
                                            name="Home" component={HomeScreen}
                                            options={{headerTitleAlign: 'center', title: 'sto pompek app'}}
                                        />
                                        <Stack.Screen
                                            name="Test" component={PushupTestScreen}
                                            options={{headerTitleAlign: 'center', title: 'Test pompek'}}
                                        />
                                        <Stack.Screen
                                            name="Day" component={CycleDayScreen}
                                            options={{headerTitleAlign: 'center', title: 'Cykl treningowy'}}
                                        />
                                        <Stack.Screen
                                            name="Warmup" component={WarmupScreen}
                                            options={{headerTitleAlign: 'center', title: 'Rozgrzewka'}}
                                        />
                                        <Stack.Screen
                                            name="Series" component={SeriesScreen}
                                            options={{
                                                headerTitleAlign: 'center', title: 'Trening', headerLeft: () => {
                                                    return null;
                                                }
                                            }}
                                        />
                                        <Stack.Screen
                                            name="Relax" component={RelaxScreen}
                                            options={{
                                                headerTitleAlign: 'center', title: 'Odpoczynek', headerLeft: () => {
                                                    return null;
                                                }
                                            }}
                                        />
                                        <Stack.Screen
                                            name="ThankYou" component={ThankYouScreen}
                                            options={{
                                                headerTitleAlign: 'center',
                                                title: 'Koniec treningu',
                                                headerLeft: () => {
                                                    return null;
                                                }
                                            }}
                                        />
                                    </Stack.Navigator>
                                    <StatusBar style="auto"/>
                                </NavigationContainer>
                            </CycleDateStartContext.Provider>
                        </WorkoutTimeStartContext.Provider>
                    </SeriesDataContext.Provider>
                </SeriesContext.Provider>
            </CycleContext.Provider>
        </PushupTestContext.Provider>
    );
}