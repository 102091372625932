export const seriesData610 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 5,
            2: 6,
            3: 4,
            4: 4,
            5: 5
        }
    },
    {
        "day": 2,
        "number_of_series": 5,
        "series": {
            1: 6,
            2: 7,
            3: 6,
            4: 6,
            5: 7
        }
    },
    {
        "day": 3,
        "number_of_series": 5,
        "series": {
            1: 8,
            2: 10,
            3: 7,
            4: 7,
            5: 10
        }
    },
    {
        "day": 4,
        "number_of_series": 5,
        "series": {
            1: 9,
            2: 11,
            3: 8,
            4: 8,
            5: 11
        }
    },
    {
        "day": 5,
        "number_of_series": 5,
        "series": {
            1: 10,
            2: 12,
            3: 9,
            4: 9,
            5: 13
        }
    },
    {
        "day": 6,
        "number_of_series": 5,
        "series": {
            1: 12,
            2: 13,
            3: 10,
            4: 10,
            5: 15
        }
    },

]