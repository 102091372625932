export const seriesData3135 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 17,
            2: 19,
            3: 15,
            4: 15,
            5: 20
        }
    },
    {
        "day": 2,
        "number_of_series": 8,
        "series": {
            1: 10,
            2: 10,
            3: 13,
            4: 13,
            5: 10,
            6: 10,
            7: 9,
            8: 25
        }
    },
    {
        "day": 3,
        "number_of_series": 8,
        "series": {
            1: 13,
            2: 13,
            3: 15,
            4: 15,
            5: 12,
            6: 12,
            7: 10,
            8: 30
        }
    }
]