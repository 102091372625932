export const seriesData3640 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 22,
            2: 24,
            3: 20,
            4: 20,
            5: 25
        }
    },
    {
        "day": 2,
        "number_of_series": 8,
        "series": {
            1: 15,
            2: 15,
            3: 18,
            4: 18,
            5: 15,
            6: 15,
            7: 14,
            8: 30
        }
    },
    {
        "day": 3,
        "number_of_series": 8,
        "series": {
            1: 18,
            2: 18,
            3: 20,
            4: 20,
            5: 17,
            6: 17,
            7: 15,
            8: 35
        }
    }
]