import {
    CenterViewContainer,
    DefaultButton,
    DefaultButtonText,
    DefaultText,
    GreenHeaderNoteContainer,
    GreenHeaderNoteText,
    HomeContainer,
    PushupTestInput,
    HeaderText,
    WhiteRoundedContainer,
    GhostButton,
    GhostButtonText, ModalBackground, ModalContainer, ModalButtonsContainer, SmallButtonText
} from "../styles/styles";
import {useContext, useRef, useState} from "react";
import {Modal} from "react-native";
import {PushupTestContext} from "../contexts/PushupTestContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {CycleDateStartContext} from "../contexts/CycleDateStartContext";
import {SafeAreaView} from "react-native-safe-area-context";

export default function PushupTestScreen({navigation}) {
    const inputRef = useRef(null);
    const {pushupTest, setPushupTest} = useContext(PushupTestContext);
    const {cycleDateStart} = useContext(CycleDateStartContext);
    const [test, setTest] = useState("");
    const [modalToggle, setModalToggle] = useState(false);

    const textValidate = (text) => {
        if (text.length > 0) {
            let regex =
                /^(\d)+$/;
            if (regex.test(text)) {
                setTest(text);
            }
        } else setTest("");
    }

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days');
    const dayBeforeYesterday = moment().subtract(2, 'days');

    const savePushupsTestInStorage = async (value) => {
        try {
            await AsyncStorage.setItem('@pushups_done_on_test', value);
        } catch (e) {
            console.error(e)
        }
    }

    const storePushupTestDay = async (day) => {
        try {
            await AsyncStorage.setItem('@pushupTestDate', day);
        } catch (e) {
            console.log("error on save pushup test date:", e);
        }
    }

    const handleChangePushupDateAndGoToHome = (value) => {
        storePushupTestDay(value)
            .then(navigation.navigate("Home"));
    }

    const handleSavePushupTestAndGoToHome = (value) => {
        if (test !== "") {
            savePushupsTestInStorage(value)
                .then(() => {
                    setPushupTest(value);
                }).then(() => storePushupTestDay(today)).then(navigation.navigate("Home"))
        }
    }

    return (
        <>
            <Modal
                visible={modalToggle === true}
                onRequestClose={() => setModalToggle(false)}
                animationType='slide'
            >
                <ModalBackground>
                    <SafeAreaView style={{flex: 1}}>
                        <ModalContainer>
                            <GreenHeaderNoteContainer style={{marginBottom: 20}}>
                                <GreenHeaderNoteText>
                                    Zapisana data
                                    testu:{'\n'}{moment(cycleDateStart).format('DD')}.{moment(cycleDateStart).format('MM')}, {moment(cycleDateStart).format('dddd')}
                                </GreenHeaderNoteText>
                            </GreenHeaderNoteContainer>
                            <WhiteRoundedContainer>
                                <CenterViewContainer>
                                    <DefaultText>
                                        Możesz zmienić zapisaną datę testu,{'\n'}lub zamknąć bez zmian.
                                    </DefaultText>
                                </CenterViewContainer>
                            </WhiteRoundedContainer>
                            <CenterViewContainer>
                                <HeaderText>Zmień datę na:</HeaderText>
                            </CenterViewContainer>
                            <ModalButtonsContainer>
                                <DefaultButton
                                    onPress={() => handleChangePushupDateAndGoToHome(today)}
                                    disabled={pushupTest === ""}
                                    style={{
                                        backgroundColor: "#00aa92",
                                        borderBottomColor: "#007964",
                                        margin: 10
                                    }}>
                                    <SmallButtonText>
                                        DZISIAJ • {moment(today).format('DD')}.{moment(today).format('MM')}
                                    </SmallButtonText>
                                </DefaultButton>
                                <DefaultButton
                                    onPress={() => handleChangePushupDateAndGoToHome(yesterday)}
                                    disabled={pushupTest === ""}
                                    style={{
                                        backgroundColor: "#00aa92",
                                        borderBottomColor: "#007964",
                                        margin: 10
                                    }}>
                                    <SmallButtonText
                                    >
                                        WCZORAJ • {moment(yesterday).format('DD')}.{moment(yesterday).format('MM')}
                                    </SmallButtonText>
                                </DefaultButton>
                                <DefaultButton
                                    onPress={() => handleChangePushupDateAndGoToHome(dayBeforeYesterday)}
                                    disabled={pushupTest === ""}
                                    style={{
                                        backgroundColor: "#00aa92",
                                        borderBottomColor: "#007964",
                                        paddingHorizontal: 40,
                                        margin: 10
                                    }}>
                                    <SmallButtonText
                                    >
                                        PRZEDWCZORAJ
                                        • {moment(dayBeforeYesterday).format('DD')}.{moment(dayBeforeYesterday).format('MM')}
                                    </SmallButtonText>
                                </DefaultButton>
                            </ModalButtonsContainer>
                            <GhostButton
                                onPress={() => setModalToggle(false)}
                                disabled={pushupTest === ""}
                                style={{marginTop: 20}}>
                                <GhostButtonText
                                >
                                    ZAMKNIJ BEZ ZMIAN
                                </GhostButtonText>
                            </GhostButton>
                        </ModalContainer>
                    </SafeAreaView>
                </ModalBackground>
            </Modal>
            <HomeContainer  source={require('../../assets/light_pushup_drawings_background.png')}>
                {pushupTest && (
                    <GreenHeaderNoteContainer>
                        <GreenHeaderNoteText>
                            Zapisany wynik: {pushupTest}
                        </GreenHeaderNoteText>
                    </GreenHeaderNoteContainer>
                )}
                <CenterViewContainer>
                    <HeaderText>Wykonaj maksymalną liczbę pompek</HeaderText>
                </CenterViewContainer>
                <WhiteRoundedContainer>
                    <CenterViewContainer style={{marginBottom: 10}}>
                        <DefaultText>
                            Pompki rób powoli i{'\u00a0'}dokładnie.
                        </DefaultText>
                    </CenterViewContainer>
                    <CenterViewContainer>
                        <DefaultText>
                            Po teście zrób dzień odpoczynku.
                        </DefaultText>
                    </CenterViewContainer>
                </WhiteRoundedContainer>
                <WhiteRoundedContainer>
                    <CenterViewContainer style={{marginBottom: 10}}>
                        <DefaultText>
                            Uzyskany wynik wpisz poniżej i{'\u00a0'}zapisz.
                        </DefaultText>
                    </CenterViewContainer>
                    <CenterViewContainer>
                        <PushupTestInput
                            ref={inputRef}
                            onChangeText={(e) => textValidate(e)}
                            value={test}
                            inputMode="numeric"
                            textAlign="center"
                            maxLength={2}
                            keyboardType="numeric"
                        />
                    </CenterViewContainer>
                    <DefaultButton
                        onPress={() => handleSavePushupTestAndGoToHome(test)}
                        disabled={test === ""}
                        style={[test === "" && {"opacity": 0.3}, {
                            backgroundColor: "#00aa92",
                            borderBottomColor: "#007964"
                        }]}>
                        <DefaultButtonText
                        >
                            ZAPISZ
                        </DefaultButtonText>
                    </DefaultButton>
                </WhiteRoundedContainer>
                <GhostButton
                    onPress={() => setModalToggle(true)}
                    disabled={pushupTest === ""}
                    style={[pushupTest === "" && {"opacity": 0.3}]}>
                    <GhostButtonText
                    >
                        ZMIEŃ DATĘ TESTU
                    </GhostButtonText>
                </GhostButton>
            </HomeContainer>
        </>

    )
}