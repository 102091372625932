export const polishNumbersFemine = {
    1: "jedną",
    2: "dwie",
    3: "trzy",
    4: "cztety",
    5: "pięć",
    6: "szesć",
    7: "siedem",
    8: "osiem",
    9: "dziewięć",
    10: "dziesięć",
    11: "jedenaście",
    12: "dwanaście",
    13: "trzynaście",
    14: "czternaście",
    15: "piętnaście",
    16: "szesnaście",
    17: "siedemnaście",
    18: "osiemnaście",
    19: "dziewiętnaście",
    20: "dwadzieścia",
    21: "dwadzieścia jeden",
    22: "dwadzieścia dwie",
    23: "dwadzieścia trzy",
    24: "dwadzieścia cztery",
    25: "dwadzieścia pięć",
    26: "dwadzieścia sześć",
    27: "dwadzieścia siedem",
    28: "dwadzieścia osiem",
    29: "dwadzieścia dziewięć",
    30: "trzydzieści",
    31: "trzydzieści jeden",
    32: "trzydzieści dwie",
    33: "trzydzieści trzy",
    34: "trzydzieści cztery",
    35: "trzydzieści pięć",
    36: "trzydzieści sześć",
    37: "trzydzieści siedem",
    38: "trzydzieści osiem",
    39: "trzydzieści dziewięć",
    40: "czterdzieści",
    41: "czterdzieści jeden",
    42: "czterdzieści dwie",
    43: "czterdzieści trzy",
    44: "czterdzieści cztery",
    53: "pięćdziesiąt trzy",
    55: "pięćdziesiąt pięć",
    58: "pięćdziesiąt osiem",
    59: "pięćdziesiąt dziewięć",
    60: "sześćdziesiąt",



}