import {useEffect, useState} from "react";
import {
    CountdownNumbers,
    HomeContainer,
    HeaderText,
    NoButton, NoButtonText
} from "../styles/styles";
import * as Speech from "expo-speech";
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {ImageBackground} from "react-native";

export default function WarmupScreen({navigation}) {
    const [secondsLeft, setSecondsLeft] = useState(60) //60
    useEffect(() => {
        const timer = setInterval(() => {
            if (secondsLeft > 0) {
                setSecondsLeft(secondsLeft - 1)
            } else if (secondsLeft === 0) {
                clearInterval(timer);
                goToSeriesView();
            }
        }, 1000);
        return () => clearInterval(timer)
    }, [secondsLeft]);

    useEffect(() => {
        const thingToSay = "Czas na rozgrzewkę";
        Speech.speak(thingToSay, {language: "pl"});
    }, [])

    const goToSeriesView = () => {
        navigation.navigate("Series");
    }

    return (
        <HomeContainer style={{justifyContent: "center"}}
                       source={require('../../assets/light_pushup_drawings_background.png')}
        >
            <ImageBackground source={require('../../assets/stopwatch.png')}
            style={{width: 258, height: 300}}>
                <AnimatedCircularProgress
                    style={{position: "absolute", top: 66, left: 24}}
                    duration={60000}
                    size={210}
                    width={20}
                    fill={100}
                    tintColor="#f75a46"
                    backgroundColor="#dadbe3"
                    rotation={0}
                    dashedBackground={
                        { width: 4, gap: 12 }
                    }
                >
                    {
                        () => (
                            <>
                                <CountdownNumbers>
                                    {secondsLeft}
                                </CountdownNumbers>
                                <HeaderText style={{backgroundColor: "none"}}>
                                    sekund
                                </HeaderText>
                            </>
                        )
                    }</AnimatedCircularProgress>
            </ImageBackground>
            <NoButton onPress={() => goToSeriesView()}>
                <NoButtonText>POMIŃ</NoButtonText>
            </NoButton>
        </HomeContainer>
    )
}