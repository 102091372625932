export const seriesData2125 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 12,
            2: 17,
            3: 13,
            4: 13,
            5: 17
        }
    },
    {
        "day": 2,
        "number_of_series": 5,
        "series": {
            1: 14,
            2: 19,
            3: 14,
            4: 14,
            5: 19
        }
    },
    {
        "day": 3,
        "number_of_series": 5,
        "series": {
            1: 16,
            2: 21,
            3: 15,
            4: 15,
            5: 21
        }
    },
    {
        "day": 4,
        "number_of_series": 5,
        "series": {
            1: 18,
            2: 22,
            3: 16,
            4: 16,
            5: 21
        }
    },
    {
        "day": 5,
        "number_of_series": 5,
        "series": {
            1: 20,
            2: 25,
            3: 20,
            4: 20,
            5: 23
        }
    },
    {
        "day": 6,
        "number_of_series": 5,
        "series": {
            1: 23,
            2: 28,
            3: 22,
            4: 22,
            5: 25
        }
    },

]