import styled from "styled-components/native";

export const HomeContainer = styled.ImageBackground`
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f1f1f8;
  padding: 0 20px;
`;

export const CenterViewContainer = styled.View`
  align-items: center;
`;

export const InputHeader = styled.Text`
  font-family: sans-serif;
  font-size: 4rem;
`;

export const PushupTestInput = styled.TextInput`
  height: 4rem;
  border: 1px solid #3f464c;
  font-family: sans-serif;
  font-size: 2rem;
  width: 80px;
  margin-top: 10px;
  text-align: center;
  border-radius: 5px;
`;

export const CycleSelectHeader = styled.Text`
  font-family: sans-serif;
  font-size: 2rem;
  margin-top: 30px;
`;

export const CycleSelectContainer = styled.View`
  width: 100%;
  flex-direction: row;
  gap: 5px;
  padding: 0 5px;
  align-items: center;
  justify-content: space-between;
`;

export const CycleDayColumn = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  flex: 1;
`;

export const CycleSelectItem = styled.TouchableOpacity`
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #f75a46;
`;

export const CycleRestDay = styled.View`
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #d8eaee;
  flex: 1;
`;

export const ActiveDaySelection = styled.View`
  position: absolute;
  width: 100%;
  height: calc(100%);
  border-radius: 4px;
  border: 2px solid #3f464c;
  bottom: 0;
  justify-content: flex-end;
  //border-bottom-width: 1px;
`;

export const ActiveDaySelectionBottom = styled(ActiveDaySelection)`
  bottom: unset;
  top: 0;
  justify-content: flex-end;
  border-top-width: 2px;
  border-bottom-width: 1px;
`;

export const FutureDayLockContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: flex-end;
  padding: 1px;
`;

export const PastDayDoneContainer = styled.ImageBackground`
  position: absolute;
  top: -5px;
  right: -2px;
  width: 24px;
  height: 24px;
  align-items: flex-end;
`;

export const PastDayCheckIcon = styled.Text`
  color: #00aa92;
  font-size: .7rem;
`;

export const FutureDayLockIcon = styled.Text`
  font-size: .8rem;
`;

export const ActiveDaySelectionText = styled.Text`
  font-family: sans-serif;
  font-size: .6rem;
  color: white;
  text-align: center;
`;

export const CycleTestDay = styled(CycleRestDay)`
  border-width: 0;
  background-color: #00aa92;
`;

export const CycleRestDayIcon = styled.View`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #c5c5c5;
  position: relative;

`;

export const CycleWorkoutDayIcon = styled.View`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #f75a46;
`;

export const CycleRestDayTextContainer = styled.View`
  align-items: center;
  justify-content: center;
`;


export const CycleRestDayText = styled.Text`
  font-family: sans-serif;
  font-size: 1.5rem;
  color: #00aa92;
  text-align: center;
`;

export const CycleSelectItemText = styled.Text`
  font-family: sans-serif;
  font-size: 1.2rem;
  color: white;
  text-align: center;
`;

export const DefaultButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f75a46;
  border-radius: 55px;
  margin: 20px 0;
  padding: 10px 20px;
  border-bottom-width: 2px;
  border-bottom-color: #c74636;
`;

export const GhostButton = styled(DefaultButton)`
  background-color: white;
  border-color: rgba(166, 166, 166, 0.5);
  border-bottom-color: rgba(166, 166, 166, 0.5);
  border-width: 1px;
  margin: 0;
`;

export const BigButton = styled(DefaultButton)`
  width: 80%;
  padding: 30px;
`;

export const NoButton = styled(DefaultButton)`
  position: absolute;
  bottom: 50px;
  background-color: unset;
  border: none;
  border-bottom-width: 0;
`;

export const DefaultButtonText = styled.Text`
  font-family: sans-serif;
  font-size: 1.2rem;
  color: white;
`;

export const GhostButtonText = styled(DefaultButtonText)`
  color: #3f464c;
  font-size: 1rem;
`;

export const NoButtonText = styled(DefaultButtonText)`
  color: #3f464c;
  background-color: #f1f1f8;
`;

export const CycleDay = styled.Text`
  font-family: sans-serif;
  font-size: 1.2rem;
  opacity: 0.5;
`;

export const SeriesNumberContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const HeaderText = styled.Text`
  font-family: monospace;
  font-size: 1.5rem;
  text-align: center;
  color: #3f464c;
  background-color: #f1f1f8;
`;

export const DefaultText = styled.Text`
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: #3f464c;
`;

export const ToDoPushupsMessage = styled.Text`
  font-family: sans-serif;
  font-size: 1.5rem;
  text-align: center;
`;

export const PushupsNumber = styled.Text`
  font-family: sans-serif;
  font-size: 8rem;
`;

export const CountdownFinishedContainer = styled.View`
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  align-items: center;
  justify-content: center;
`;

export const SeriesNumberBulletsContainer = styled.View`
  flex-direction: row;
  margin-top: 5px;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const SeriesNumberBulletsContainerBackgroundLine = styled.View`
  position: absolute;
  background-color: #D7D7D7FF;
  height: 9px;
  width: 100%;
  z-index: 1;
`;

export const SeriesNumberBullet = styled.View`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f75a46;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const SeriesNumberBulletText = styled.Text`
  font-family: sans-serif;
  font-size: 1rem;
  color: white;
`;

export const HomeScreenImage = styled.Image`
  width: 200px;
  height: 200px;
`;

export const CountdownContainer = styled.View`
  height: 300px;
  width: 300px;
  border: 8px solid black;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const CountdownNumbers = styled.Text`
  font-family: sans-serif;
  font-size: 5rem;
  color: #3f464c;
`;

export const AppVersionContainer = styled.View`
  position: absolute;
  bottom: 40px;
  right: 10px;
`;

export const AppVersion = styled.Text`
  font-family: monospace;
  font-size: .9rem;
  color: grey;
  background-color: #f1f1f8;
`;

export const WhiteRoundedContainer = styled.View`
  gap: 5px;
  background-color: white;
  border-radius: 6px;
  padding: 20px 10px;
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-color: #e1e1e1;
  border-left-width: 1px;
  border-left-color: #efeff6;
  border-right-width: 1px;
  border-right-color: #efeff6;
  border-top-width: 1px;
  border-top-color: #efeff6;
`;

export const GreenHeaderNoteContainer = styled.View`
  padding: 20px;
  width: 100%;
  background-color: #d8eaee;
  border-radius: 6px;
`;

export const GreenHeaderNoteText = styled.Text`
  color: #00aa92;
  font-size: 1rem;
  text-align: center;
  line-height: 1.6rem;
`;

export const CalendarWeeksHeader = styled.View`
  flex-direction: row;
  border-bottom-width: 2px;
  border-bottom-color: #f1f1f8;

`;

export const ModalBackground = styled.View`
  flex: 1;
  background-color: #f1f1f8;
`;

export const ModalContainer = styled.View`
  flex: 1;
  background-color: #f1f1f8;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ModalButtonsContainer = styled.View`
  //padding: 0 20px;
`;


export const SmallButtonText = styled(DefaultButtonText)`
  font-size: 1rem;
`;