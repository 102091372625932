import {useContext, useEffect, useState} from "react";
import {CycleContext} from "../contexts/CycleContext";
import {
    CountdownNumbers,
    HomeContainer,
    HeaderText, NoButton, NoButtonText
} from "../styles/styles";
import {SeriesContext} from "../contexts/SeriesContext";
import * as Speech from "expo-speech";
import {ImageBackground} from "react-native";
import {AnimatedCircularProgress} from "react-native-circular-progress";
import {SeriesDataContext} from "../contexts/SeriesDataContex";

export default function RelaxScreen({navigation}) {
    const {cycleNumber} = useContext(CycleContext);
    const {seriesNumber, setSeriesNumber} = useContext(SeriesContext);
    const {seriesData} = useContext(SeriesDataContext);
    const [secondsToCountdown, setSecondsToCountdown] = useState(60);

    useEffect(() => {
        const cycle = parseInt(cycleNumber);
        const currentCycleLength = seriesData.length;
        if (currentCycleLength === 3) {
            setSecondsToCountdown(45) //45
        } else {
            if (cycle === 1 || cycle === 4) {
                setSecondsToCountdown(60) //60
            } else if (cycle === 2 || cycle === 5) {
                setSecondsToCountdown(90) //90
            } else {
                setSecondsToCountdown(120) //120
            }
        }
    }, []);

    const checkDuration = () => {
        const cycle = parseInt(cycleNumber);
        const currentCycleLength = seriesData.length;
        if (currentCycleLength === 3) {
            return 45000
        } else {
            if (cycle === 1 || cycle === 4) {
                return 60000
            } else if (cycle === 2 || cycle === 5) {
                return 90000
            } else {
                return 120000
            }
        }
    }

    const goToSeriesView = () => {
        setSeriesNumber(parseInt(seriesNumber) + 1);
        navigation.navigate("Series");
    }

    useEffect(() => {
        const thingToSay = "Czas odpocząć";
        Speech.speak(thingToSay, {language: "pl"});
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            if (secondsToCountdown > 0) {
                setSecondsToCountdown(secondsToCountdown - 1)
            } else if (secondsToCountdown === 0) {
                goToSeriesView();
            }
        }, 1000);
        return () => clearInterval(timer)
    }, [secondsToCountdown]);

    return (
        <HomeContainer style={{justifyContent: "center"}}
                       source={require('../../assets/light_pushup_drawings_background.png')}
        >
            <ImageBackground source={require('../../assets/stopwatch.png')}
                             style={{width: 258, height: 300}}>
                <AnimatedCircularProgress
                    style={{position: "absolute", top: 66, left: 24}}
                    duration={checkDuration()}
                    size={210}
                    width={20}
                    fill={100}
                    tintColor="#f75a46"
                    backgroundColor="#dadbe3"
                    rotation={0}
                    dashedBackground={
                        {width: 4, gap: 12}
                    }

                >
                    {
                        () => (
                            <>
                                <CountdownNumbers>
                                    {secondsToCountdown}
                                </CountdownNumbers>
                                <HeaderText  style={{backgroundColor: "none"}}>
                                    sekund
                                </HeaderText>
                            </>
                        )
                    }</AnimatedCircularProgress>
            </ImageBackground>
            <NoButton onPress={() => goToSeriesView()}>
                <NoButtonText>POMIŃ</NoButtonText>
            </NoButton>
        </HomeContainer>
    )
}