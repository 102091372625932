export const seriesData5660 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 30,
            2: 44,
            3: 40,
            4: 40,
            5: 55
        }
    },
    {
        "day": 2,
        "number_of_series": 9,
        "series": {
            1: 22,
            2: 22,
            3: 27,
            4: 27,
            5: 24,
            6: 23,
            7: 18,
            8: 18,
            9: 58
        }
    },
    {
        "day": 3,
        "number_of_series": 9,
        "series": {
            1: 26,
            2: 26,
            3: 33,
            4: 33,
            5: 26,
            6: 26,
            7: 22,
            8: 22,
            9: 60
        }
    }
]