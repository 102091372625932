import React, {useContext} from 'react';
import {Text, View} from 'react-native';
import moment from 'moment';
import {ActiveDaySelection} from "../styles/styles";
import {PushupTestContext} from "../contexts/PushupTestContext";


const Calendar = ({date}) => {
    const {pushupTest} = useContext(PushupTestContext);

    const startDate = moment(date).startOf('isoWeek');
    const today = moment();
    const selectedDate = moment(date);
    const days = [];
    const shortCycle = pushupTest > 30;

    for (let i = 0; i < 28; i++) {
        const day = moment(startDate).add(i, 'days');
        const isToday = today.isSame(day, 'day');
        const isSelectedDate = selectedDate.isSame(day, 'day');
        const isBeforeToday = day.isBefore(today, 'day');
        const isFirstWorkoutDate = moment(date).add(2, 'days').isSame(day, 'day');
        const isSecondWorkoutDate = moment(date).add(4, 'days').isSame(day, 'day');
        const isThirdWorkoutDate = moment(date).add(6, 'days').isSame(day, 'day');
        const isFourthWorkoutDate = moment(date).add(9, 'days').isSame(day, 'day');
        const isFifthWorkoutDate = moment(date).add(11, 'days').isSame(day, 'day');
        const isSixthWorkoutDate = moment(date).add(13, 'days').isSame(day, 'day');
        const lastDay = moment(date).add(shortCycle ? 9 : 16, 'days');
        const isLastDay = lastDay.isSame(day, 'day');

        let opacity = 1;
        if (isBeforeToday) {
            opacity = 0.3;
        }

        const checkIsWorkout = () => {
            return shortCycle ?
                isFirstWorkoutDate || isSecondWorkoutDate || isThirdWorkoutDate
                : isFirstWorkoutDate || isSecondWorkoutDate || isThirdWorkoutDate || isFourthWorkoutDate || isFifthWorkoutDate || isSixthWorkoutDate
        }

        days.push(
            <View
                key={i}
                style={{
                    backgroundColor: isSelectedDate || isLastDay ? '#00aa92' : checkIsWorkout() ? "#f75a46" : undefined,
                    borderRadius: 5,
                    opacity: opacity,
                    flex: 1,
                    aspectRatio: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        textAlign: "center",
                        color: isSelectedDate || isLastDay || checkIsWorkout() ? "white" : undefined
                    }}>{day.format('D')}{'\n'}{isToday && "DZIŚ"}</Text>
                {isToday && <ActiveDaySelection/>}
            </View>
        );
    }

    const rows = [];
    let cells = [];

    days.forEach((day, index) => {
        if (index % 7 !== 0) {
            cells.push(day);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(day);
        }
        if (index === days.length - 1) {
            rows.push(cells);
        }
    });

    return (
        <View style={{marginTop: 5, gap: 4}}>
            {rows.map((row, index) => (
                <View key={index} style={{flexDirection: 'row', gap: 2}}>
                    {row}
                </View>
            ))}
        </View>
    );
};

export default Calendar;
