import {
    DefaultButton,
    DefaultButtonText,
    HomeContainer,
    HeaderText,
    GreenHeaderNoteText, GreenHeaderNoteContainer
} from "../styles/styles";
import {useContext, useEffect} from "react";
import {CycleContext} from "../contexts/CycleContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Speech from "expo-speech";
import {BackHandler} from "react-native";
import {WorkoutTimeStartContext} from "../contexts/WorkoutTimeStartContext";

export default function ThankYouScreen({navigation}) {
    const {cycleNumber} = useContext(CycleContext)
    const {workoutTimeStart} = useContext(WorkoutTimeStartContext);

    useEffect(() => {
        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            () => true
        );
        return () => backHandler.remove();
    }, []);

    const handleEndSeries = () => {
        navigation.navigate("Home")
    }

    const totalWorkoutTime = () => {
        const startTime = workoutTimeStart;
        const endTime = new Date();
        const diff = endTime - startTime
        const total = new Date(diff);
        return (
            <GreenHeaderNoteText>
                Czas treningu: {total.getMinutes() > 0 && `${total.getMinutes()} min`} {total.getSeconds()} sek.
            </GreenHeaderNoteText>
        )
    }


    useEffect(() => {
        const thingToSay = "Gratki, dobra robota!";
        Speech.speak(thingToSay, {language: "pl"});
    }, [])

    useEffect( () => {
        const storeEndedCycle = async () => {
            try {
                await AsyncStorage.setItem('@last_cycle_done', cycleNumber);
            } catch (e) {
                console.log("error on save cycle done:", e);
            }
        }
        storeEndedCycle();
    }, [])

    useEffect(() => {
        const storeFirstDayDate = async () => {
            if (cycleNumber === 1) {
                const todayDate = new Date();
                try {
                    await AsyncStorage.setItem('@first_cycle_day', todayDate);
                } catch (e) {
                    console.log("error on save first cycle date:", e);
                }
            }
        };
        storeFirstDayDate();
    })

    const whatToDoNext = () => {
        if (cycleNumber === 3) {
            return "2 dni przerwy"
        } else if (cycleNumber === 6) {
            return (
                <>2 dni przerwy i{'\u00a0'}wykonaj ponownie test</>
            )
        } else {
            return "1 dzień przerwy"
        }
    }

    return (
        <HomeContainer style={{"justifyContent": "space-evenly"}}
                       source={require('../../assets/light_pushup_drawings_background.png')}
        >
            <GreenHeaderNoteContainer>
                {totalWorkoutTime()}
            </GreenHeaderNoteContainer>
            <HeaderText>
                Gratki!
            </HeaderText>
            <HeaderText>
                Zrób sobie{"\n"}{whatToDoNext()}.
            </HeaderText>
            <DefaultButton onPress={() => handleEndSeries()}>
                <DefaultButtonText>
                    ZAKOŃCZ
                </DefaultButtonText>
            </DefaultButton>
        </HomeContainer>
    )
}