export const seriesDataAbove60 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 35,
            2: 49,
            3: 45,
            4: 45,
            5: 55
        }
    },
    {
        "day": 2,
        "number_of_series": 9,
        "series": {
            1: 22,
            2: 22,
            3: 30,
            4: 30,
            5: 24,
            6: 24,
            7: 18,
            8: 18,
            9: 59
        }
    },
    {
        "day": 3,
        "number_of_series": 9,
        "series": {
            1: 28,
            2: 28,
            3: 35,
            4: 35,
            5: 27,
            6: 27,
            7: 23,
            8: 23,
            9: 60
        }
    }
]