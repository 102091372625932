export const polishPushupDeclention = {
    1: "pompkę",
    2: "pompki",
    3: "pompki",
    4: "pompki",
    5: "pompek",
    6: "pompek",
    7: "pompek",
    8: "pompek",
    9: "pompek",
    10: "pompek",
    11: "pompek",
    12: "pompek",
    13: "pompek",
    14: "pompek",
    15: "pompek",
    16: "pompek",
    17: "pompek",
    18: "pompek",
    19: "pompek",
    20: "pompek",
    21: "pompek",
    22: "pompki",
    23: "pompki",
    24: "pompki",
    25: "pompek",
    26: "pompek",
    27: "pompek",
    28: "pompek",
    29: "pompek",
    30: "pompek",
    31: "pompek",
    32: "pompki",
    33: "pompki",
    34: "pompki",
    35: "pompek",
    36: "pompek",
    37: "pompek",
    38: "pompek",
    39: "pompek",
    40: "pompek",
    41: "pompek",
    42: "pompki",
    43: "pompki",
    44: "pompki",
    53: "pompki",
    55: "pompek",
    58: "pompek",
    59: "pompek",
    60: "pompek",



}