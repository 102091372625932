import React, {useContext, useEffect, useState} from 'react';
import {View, Text} from 'react-native';
import 'moment/locale/pl';
import {StyleSheet} from "react-native";
import {
    CalendarWeeksHeader, DefaultButton, DefaultButtonText,
    GreenHeaderNoteContainer, GreenHeaderNoteText, HomeContainer,
    WhiteRoundedContainer
} from "../styles/styles";
import {PushupTestContext} from "../contexts/PushupTestContext";
import {WorkoutTimeStartContext} from "../contexts/WorkoutTimeStartContext";
import Calendar from "./Callendar";
import {CycleDateStartContext} from "../contexts/CycleDateStartContext";
import moment from "moment";
import {CycleContext} from "../contexts/CycleContext";
import {SeriesContext} from "../contexts/SeriesContext";


const CycleDayScreen = ({navigation}) => {
    const {pushupTest} = useContext(PushupTestContext);
    const {setWorkoutTimeStart} = useContext(WorkoutTimeStartContext);
    const {cycleDateStart} = useContext(CycleDateStartContext);
    const {setSeriesNumber} = useContext(SeriesContext);
    const {setCycleNumber} = useContext(CycleContext)

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const shortCycle = pushupTest > 30;

    // const cycleStartedAt = moment(cycleDateStart).subtract(9, 'd');

    const cycleStartedAt = moment(cycleDateStart).startOf('day');
    const today = moment().startOf('day');

    const cycleDay = (today.diff(cycleStartedAt, 'd'));
    console.log("cycle day:", cycleDay)

    const checkDay = (day) => {
        switch (day) {
            case 2:
            case 4:
            case 6:
                return false;
            case 9:
            case 11:
            case 13:
                return shortCycle;
            default:
                return true
        }
    }

    useEffect(() => {
        setIsButtonDisabled(checkDay(cycleDay))
    }, [])

    const handleStartWorkout = () => {
        const workoutStart = new Date();
        const dayNo = (cycleDay) => {
            switch (cycleDay) {
                case 2:
                    return 1;
                case 4:
                    return 2;
                case 6:
                    return 3;
                case 9:
                    return 4;
                case 11:
                    return 5;
                case 13:
                    return 6;
            }
        }
        setCycleNumber(dayNo(cycleDay));
        setWorkoutTimeStart(workoutStart);
        setSeriesNumber(1);
        navigation.navigate("Warmup");
    }
    return (
        <HomeContainer
            source={require('../../assets/light_pushup_drawings_background.png')}
        >
            <GreenHeaderNoteContainer>
                <GreenHeaderNoteText>
                    Wynik testu: {pushupTest} pompek{'\n'}
                </GreenHeaderNoteText>
            </GreenHeaderNoteContainer>
            <View style={{flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                <View style={{backgroundColor: '#00aa92', paddingVertical: 3, paddingHorizontal: 5, borderRadius: 5}}><Text
                    style={{color: "white", fontSize: 12}}>DZIEŃ TESTU</Text></View>
                <View style={{backgroundColor: '#f75a46', paddingVertical: 3, paddingHorizontal: 5, borderRadius: 5}}><Text
                    style={{color: "white", fontSize: 12}}>DZIEŃ TRENINGU</Text></View>
                <View style={{backgroundColor: 'white', paddingVertical: 3, paddingHorizontal: 5, borderRadius: 5}}><Text
                    style={{fontSize: 12}}>DZIEŃ WOLNY</Text></View>
            </View>
            <WhiteRoundedContainer style={{padding: 10, paddingTop: 5}}>
                <CalendarWeeksHeader>
                    <View style={styles.day}><Text>pn.</Text></View>
                    <View style={styles.day}><Text>wt.</Text></View>
                    <View style={styles.day}><Text>śr.</Text></View>
                    <View style={styles.day}><Text>czw.</Text></View>
                    <View style={styles.day}><Text>pt.</Text></View>
                    <View style={styles.day}><Text>sb.</Text></View>
                    <View style={styles.day}><Text>nd.</Text></View>
                </CalendarWeeksHeader>
                <Calendar
                    date={cycleStartedAt}
                />
            </WhiteRoundedContainer>
            <DefaultButton
                onPress={() => handleStartWorkout()}
                style={isButtonDisabled && {opacity: 0.3}}
                disabled={isButtonDisabled}
            >
                <DefaultButtonText>
                    JAZDA!
                </DefaultButtonText>
            </DefaultButton>
        </HomeContainer>
    );
};

export default CycleDayScreen;


const styles = StyleSheet.create({
    day: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
});