export const seriesData1120 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 8,
            2: 9,
            3: 7,
            4: 7,
            5: 8
        }
    },
    {
        "day": 2,
        "number_of_series": 5,
        "series": {
            1: 9,
            2: 10,
            3: 8,
            4: 8,
            5: 10
        }
    },
    {
        "day": 3,
        "number_of_series": 5,
        "series": {
            1: 11,
            2: 13,
            3: 9,
            4: 9,
            5: 13
        }
    },
    {
        "day": 4,
        "number_of_series": 5,
        "series": {
            1: 12,
            2: 14,
            3: 10,
            4: 10,
            5: 15
        }
    },
    {
        "day": 5,
        "number_of_series": 5,
        "series": {
            1: 13,
            2: 15,
            3: 11,
            4: 11,
            5: 17
        }
    },
    {
        "day": 6,
        "number_of_series": 5,
        "series": {
            1: 14,
            2: 16,
            3: 13,
            4: 13,
            5: 19
        }
    },

]