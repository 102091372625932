export const seriesData2630 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 14,
            2: 18,
            3: 14,
            4: 14,
            5: 20
        }
    },
    {
        "day": 2,
        "number_of_series": 5,
        "series": {
            1: 20,
            2: 25,
            3: 15,
            4: 15,
            5: 23
        }
    },
    {
        "day": 3,
        "number_of_series": 5,
        "series": {
            1: 20,
            2: 27,
            3: 18,
            4: 18,
            5: 25
        }
    },
    {
        "day": 4,
        "number_of_series": 5,
        "series": {
            1: 21,
            2: 25,
            3: 21,
            4: 21,
            5: 27
        }
    },
    {
        "day": 5,
        "number_of_series": 5,
        "series": {
            1: 25,
            2: 29,
            3: 25,
            4: 25,
            5: 30
        }
    },
    {
        "day": 6,
        "number_of_series": 5,
        "series": {
            1: 29,
            2: 33,
            3: 29,
            4: 29,
            5: 33
        }
    },

]