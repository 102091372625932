export const seriesData4145 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 27,
            2: 29,
            3: 25,
            4: 25,
            5: 35
        }
    },
    {
        "day": 2,
        "number_of_series": 8,
        "series": {
            1: 19,
            2: 19,
            3: 22,
            4: 22,
            5: 18,
            6: 18,
            7: 22,
            8: 35
        }
    },
    {
        "day": 3,
        "number_of_series": 8,
        "series": {
            1: 20,
            2: 20,
            3: 24,
            4: 24,
            5: 20,
            6: 20,
            7: 22,
            8: 40
        }
    }
]