export const seriesData4650 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 30,
            2: 34,
            3: 30,
            4: 30,
            5: 40
        }
    },
    {
        "day": 2,
        "number_of_series": 8,
        "series": {
            1: 19,
            2: 19,
            3: 23,
            4: 23,
            5: 19,
            6: 19,
            7: 22,
            8: 37
        }
    },
    {
        "day": 3,
        "number_of_series": 8,
        "series": {
            1: 20,
            2: 20,
            3: 27,
            4: 27,
            5: 21,
            6: 21,
            7: 21,
            8: 44
        }
    }
]