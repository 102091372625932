export const seriesData5155 = [
    {
        "day": 1,
        "number_of_series": 5,
        "series": {
            1: 30,
            2: 39,
            3: 35,
            4: 35,
            5: 42
        }
    },
    {
        "day": 2,
        "number_of_series": 9,
        "series": {
            1: 20,
            2: 20,
            3: 23,
            4: 23,
            5: 20,
            6: 20,
            7: 18,
            8: 18,
            9: 53
        }
    },
    {
        "day": 3,
        "number_of_series": 9,
        "series": {
            1: 22,
            2: 22,
            3: 30,
            4: 30,
            5: 25,
            6: 25,
            7: 18,
            8: 18,
            9: 55
        }
    }
]