import {useContext, useEffect} from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    AppVersion, AppVersionContainer,
    BigButton,
    CenterViewContainer,
    DefaultButton, DefaultButtonText,
    HomeContainer, HomeScreenImage,
} from "../styles/styles";
import {PushupTestContext} from "../contexts/PushupTestContext";
import {SeriesDataContext} from "../contexts/SeriesDataContex";
import {seriesDataBelow6} from "../data/seriesDataBelow6";
import {seriesData610} from "../data/seriesData6-10";
import {seriesData1120} from "../data/seriesData11-20";
import {seriesData2125} from "../data/seriesData21-25";
import {seriesData2630} from "../data/seriesData26-30";
import {seriesData3135} from "../data/seriesData31-35";
import {seriesData3640} from "../data/seriesData36-40";
import {seriesData4145} from "../data/seriesData41-45";
import {useIsFocused} from "@react-navigation/native";
import {seriesData4650} from "../data/seriesData46-50";
import {seriesData5155} from "../data/seriesData51-55";
import {seriesData5660} from "../data/seriesData56-60";
import {seriesDataAbove60} from "../data/seriesDataAbove60";
import Constants from "expo-constants"
import {CycleDateStartContext} from "../contexts/CycleDateStartContext";

export default function HomeScreen({navigation}) {
    const {pushupTest, setPushupTest} = useContext(PushupTestContext);
    const {setSeriesData} = useContext(SeriesDataContext);
    const isFocused = useIsFocused();
    const {setCycleDateStart} = useContext(CycleDateStartContext);

    useEffect(() => {
        console.log("pushupTest", pushupTest)
        if (pushupTest) {
            if (pushupTest <= 5) {
                setSeriesData(seriesDataBelow6)
            } else if (pushupTest <= 10) {
                setSeriesData(seriesData610)
            } else if (pushupTest <= 20) {
                setSeriesData(seriesData1120)
            } else if (pushupTest <= 25) {
                setSeriesData(seriesData2125)
            } else if (pushupTest <= 30) {
                setSeriesData(seriesData2630)
            } else if (pushupTest <= 35) {
                setSeriesData(seriesData3135)
            } else if (pushupTest <= 40) {
                setSeriesData(seriesData3640)
            } else if (pushupTest <= 45) {
                setSeriesData(seriesData4145)
            } else if (pushupTest <= 50) {
                setSeriesData(seriesData4650)
            } else if (pushupTest <= 55) {
                setSeriesData(seriesData5155)
            } else if (pushupTest <= 60) {
                setSeriesData(seriesData5660)
            } else if (pushupTest > 60) {
                setSeriesData(seriesDataAbove60)
            }
        }
    })

    useEffect(() => {
        const firstLoad = async () => {
            try {
                const savedTest = await AsyncStorage.getItem("@pushups_done_on_test");
                setPushupTest(savedTest);
            } catch (err) {
                console.log("error on saved pushupTest:", err);
            }
            try {
                const pushupTestDay = await AsyncStorage.getItem("@pushupTestDate");
                setCycleDateStart(pushupTestDay)
            } catch (err) {
                console.log("error on saved last cycle:", err);
            }
        };
        firstLoad();
    }, [isFocused]);


    return (
        <HomeContainer style={{padding: 40}}
                       source={require('../../assets/pushup_drawings_background.png')}>
            <CenterViewContainer>
                <HomeScreenImage
                    source={require('../../assets/sto_home.png')}
                />
                <AppVersionContainer>
                    <AppVersion>
                        v.{Constants.manifest.version}
                    </AppVersion>
                </AppVersionContainer>
            </CenterViewContainer>
            <BigButton
                style={(!pushupTest || pushupTest === "0") && {opacity: 0.3}}
                onPress={() => navigation.navigate("Day")}
                disabled={!pushupTest || pushupTest === "0"}
            >
                <DefaultButtonText>
                    TRENUJ
                </DefaultButtonText>
            </BigButton>
            <DefaultButton
                onPress={() => navigation.push("Test")}
                style={[{backgroundColor: "#00aa92"}, {borderBottomColor: "#007964"}]}
            >
                <DefaultButtonText>
                    TEST
                </DefaultButtonText>
            </DefaultButton>
        </HomeContainer>
    )
}